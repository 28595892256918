<template>
  <b-modal
    id="modal__event"
    v-if="active != null"
    modal-class="main_menu__modal"
    dialog-class="main_menu__dialog"
    content-class="main_menu__content"
    body-class="main_menu__body"
    centered
    hide-footer
    hide-header
    scrollable
    size="lg"
  >
    <b-container v-if="view == 'all' && events">
      <b-row align-h="center">
        <div class="event-img__container">
          <button class="exit_button" @click="closeModal()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="red"
              />
            </svg>
          </button>

          <img
            v-if="events[active].img"
            class="event-img__img"
            :src="$store.state.storageUrl + '/app/events/' + events[active].img"
            alt=""
          />
          <img
            v-else
            class="event-img__img"
            :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
            alt=""
          />
        </div>
      </b-row>

      <b-row>
        <!-- About event -->
        <b-col cols="12" cols-md="8" class="about-event_content">
          <b-row align-h="center">
            <b-col cols="12" md="8">
              <h2
                class="title-event title-modal"
                v-html="events[active].title"
              ></h2>
              <h3
                class="description-event-modal"
                v-html="events[active].description"
              ></h3>
            </b-col>
          </b-row>

          <b-row align-h="center" class="speacker-block">
            <b-col cols="12" md="8">
              <h5 class="subtitle-event-modal">О спикере:</h5>
              <p class="spicker" v-html="events[active].speaker"></p>
              <p v-html="events[active].about_speaker"></p>
            </b-col>
          </b-row>

          <b-row align-h="center" class="event-block">
            <b-col cols="12" md="8">
              <h5 class="subtitle-event-modal">О мероприятии:</h5>
              <p class="about-event_text">
                <span class="desc_title">Когда: </span
                >{{ formatDateTime(events[active].date_time, "time") }}
                {{ formatDateTime(events[active].date_time, "date") }}
              </p>
              <p class="about-event_text">
                <span class="desc_title">Где: </span
                >{{ events[active].location }}
              </p>
              <p v-if="view != 'my'" class="about-event_text">
                <span class="desc_title">Всего мест: </span
                >{{ events[active].count_seats }}
              </p>
              <p v-if="view != 'my'" class="about-event_text">
                <span class="desc_title">Свободно: </span
                >{{ events[active].free_seats }}
              </p>
            </b-col>
          </b-row>
        </b-col>

        <!-- Registration -->
        <b-col cols="12" cols-md="8" class="reg_event">
          <b-row align-h="center">
            <b-col cols="12" md="8">
              <h3 class="text-center">Регистрация на мероприятие</h3>

              <div v-if="user" class="user-data">
                <p class="about-event_text">
                  <span v-if="user.name" class="desc_title">Имя:</span>
                  {{ user.name }}
                </p>
                <p class="about-event_text">
                  <span v-if="user.last_name" class="desc_title">Фамилия:</span>
                  {{ user.last_name }}
                </p>
                <p class="about-event_text">
                  <span v-if="user.email" class="desc_title">Email:</span>
                  {{ user.email }}
                </p>
              </div>

              <b-form @submit="register">
                <div v-if="user">
                  <b-form-checkbox
                    @change="changeBK()"
                    switch
                    id="filter_active"
                    v-model="bkUser"
                    :value="1"
                    :unchecked-value="0"
                    size="lg"
                    >Участник БК</b-form-checkbox
                  >

                  <p class="about-event_text">Сколько мест:</p>

                  <b-form-select
                    v-if="bkUser != 1"
                    v-model="selectedPrice"
                    :options="events[active].prices"
                    value-field="data"
                    text-field="data.seats"
                    required
                  ></b-form-select>

                  <b-form-select
                    v-else
                    v-model="selectedPrice"
                    :options="events[active].bk_prices"
                    value-field="data"
                    text-field="data.seats"
                    required
                  ></b-form-select>

                  <div class="row">
                    <div class="col">
                      <p class="about-event_text">Стоимость регистрации:</p>
                    </div>
                    <div class="col">
                      <h2 v-if="selectedPrice" class="price">
                        {{ selectedPrice.value }} ₽
                      </h2>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <p class="text-center">
                    Для регистрации на мероприятие необходимо войти в аккаунт
                  </p>
                </div>

                <div class="row">
                  <div class="col" v-if="user">
                    <template v-if="selectedPrice">
                      <b-button
                        v-if="selectedPrice.seats <= events[active].free_seats"
                        id="regEvent"
                        type="submit"
                        class="event__button"
                        :disabled="disableRegisterButton"
                        >Зарегистрироваться</b-button
                      >
                      <b-button
                        v-else-if="
                          selectedPrice.seats > events[active].free_seats
                        "
                        disabled
                        class="event__button"
                        >Нет свободных мест</b-button
                      >
                    </template>

                    <b-button v-else class="event__button" disabled
                      >Выберите кол-во мест</b-button
                    >
                  </div>

                  <div class="col" v-else>
                    <b-button
                      type="button"
                      @click="to('Login')"
                      class="event__button"
                      >вход/регистрация</b-button
                    >
                  </div>
                </div>
              </b-form>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else-if="view == 'my' && myEvents">
      <b-row align-h="center">
        <div class="event-img__container">
          <button class="exit_button" @click="closeModal()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="red"
              />
            </svg>
          </button>

          <img
            v-if="myEvents[active].event.img"
            class="event-img__img"
            :src="
              $store.state.storageUrl +
              '/app/events/' +
              myEvents[active].event.img
            "
            alt=""
          />
          <img
            v-else
            class="event-img__img"
            :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
            alt=""
          />
        </div>
      </b-row>

      <b-row>
        <!-- About event -->
        <b-col cols="12" cols-md="8" class="about-event_content">
          <b-row align-h="center">
            <b-col cols="12" md="8">
              <h2
                class="title-event title-modal"
                v-html="myEvents[active].event.title"
              ></h2>
              <h3
                class="description-event-modal"
                v-html="myEvents[active].event.description"
              ></h3>
            </b-col>
          </b-row>

          <b-row align-h="center" class="speacker-block">
            <b-col cols="12" md="8">
              <h5 class="subtitle-event-modal">О спикере:</h5>
              <p class="spicker" v-html="myEvents[active].event.speaker"></p>
              <p v-html="myEvents[active].event.about_speaker"></p>
            </b-col>
          </b-row>

          <b-row align-h="center" class="event-block">
            <b-col cols="12" md="8">
              <h5 class="subtitle-event-modal">О мероприятии:</h5>
              <p class="about-event_text">
                <span class="desc_title">Когда: </span
                >{{ formatDateTime(myEvents[active].event.date_time, "time") }}
                {{ formatDateTime(myEvents[active].event.date_time, "date") }}
              </p>
              <p class="about-event_text">
                <span class="desc_title">Где: </span
                >{{ myEvents[active].event.location }}
              </p>
              <p v-if="view != 'my'" class="about-event_text">
                <span class="desc_title">Всего мест: </span
                >{{ myEvents[active].event.count_seats }}
              </p>
              <p v-if="view != 'my'" class="about-event_text">
                <span class="desc_title">Свободно: </span
                >{{ myEvents[active].event.free_seats }}
              </p>
            </b-col>
          </b-row>
        </b-col>

        <!-- My -->
        <b-col cols="12" cols-md="8" class="reg_event">
          <b-row align-h="center">
            <b-col cols="12" md="8">
              <div v-if="user" class="user-data">
                <p class="about-event_text">
                  <span v-if="user.name" class="desc_title">Имя:</span>
                  {{ user.name }}
                </p>
                <p class="about-event_text">
                  <span v-if="user.last_name" class="desc_title">Фамилия:</span>
                  {{ user.last_name }}
                </p>
                <p class="about-event_text">
                  <span v-if="user.email" class="desc_title">Email:</span>
                  {{ user.email }}
                </p>
              </div>

              <div>
                <p class="about-event_text">
                  <span class="desc_title">Кол-во мест: </span
                  >{{ myEvents[active].price.seats }}
                </p>
              </div>

              <div class="row justify-content-center">
                <div class="qr-wrapper">
                  <img
                    class="qr-code"
                    v-bind:src="
                      'https://qrcode.tec-it.com/API/QRCode?data=' +
                      $store.state.appUrl +
                      '/check_registration/?id=' +
                      myEvents[active].reserve.id
                    "
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "EventModal",
  data() {
    return {
      bkUser: 0,
      selectedPrice: null,
      disableRegisterButton: false,

      //modal event
      modalEvent: null,
    };
  },
  computed: {
    active() {
      return this.$store.state.events.active;
    },
    events() {
      return this.$store.state.events.events;
    },
    myEvents() {
      return this.$store.state.events.myEvents;
    },
    user() {
      return this.$store.state.user.user;
    },
    view() {
      return this.$store.state.events.view;
    },
  },
  methods: {
    closeModal() {
      this.selectedPrice = null;
      this.$bvModal.hide("modal__event");
    },
    // DateTime format
    formatDateTime(dateTime, type) {
      moment.locale("ru");
      var result;

      switch (type) {
        case "date":
          result = moment(String(dateTime)).format("LT");
          break;

        case "time":
          result = moment(String(dateTime)).format("DD MMMM");
          break;
      }

      return result;
    },
    // Send form
    async register(event) {
      event.preventDefault();

      this.disableRegisterButton = true;

      var event_id = this.events[this.active].id;
      var price_id = this.selectedPrice.price_id;

      await axios
        .post("reserve_seat", { event_id, price_id })
        .then((res) => {
          this.disableRegisterButton = false;

          location.replace(res.data.confirmationURL);
        })
        .catch((e) => {
          this.disableRegisterButton = false;

          console.error(e);
        });
    },
    // Creating pay
    createPay(reserv_id) {
      let formData = new FormData();

      formData.append("id", this.user.id);
      formData.append("hash", this.user.hash);

      formData.append("event_id", this.events[this.modalEvent].id);
      formData.append(
        "price_id",
        this.events[this.modalEvent].prices[this.selected].id
      );
      formData.append("reserv_id", reserv_id);

      if (this.user.id !== 0 && this.user.authorized == true) {
        this.axios
          .post("https://bkteams.ru/api/pay/createPay/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            location.replace(response.data.confirmation.confirmation_url);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    changeBK() {
      this.selectedPrice = null;
    },
    to(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>

<style lang="scss">
.modal-header {
  border-bottom: 0px;
}
.modal-header .close {
  opacity: 100%;
  color: $accent;
  border-width: 0px;
  outline: none;
  box-shadow: 1px 0 0 0 rgba($black, 1);
  text-decoration: none;
  background-color: rgba($black, 0);
}
#aboutEvent {
  color: $white;
}
.about-event_content {
  padding-bottom: 30px;
}
.reg_event {
  padding-top: 30px;
  padding-bottom: 30px;

  border-color: $light_gray__dark;
  border-style: dashed;
  border-width: 2px;
}
.price_content {
  padding-bottom: 60px;
}
.price {
  padding-top: 30px;
  text-align: center;
  font-weight: 300;
}

input,
select,
.form-control,
.custom-select {
  border-radius: 0px;
  border: 0px;

  box-shadow: none;
  outline: none;

  height: 46px;

  transition: linear 200ms;
}

.custom-select:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;

  outline-width: 1px;
  outline-style: solid;
  outline-color: $accent;

  box-shadow: 0px 0px 14px 0px rgba(255, 68, 51, 0.7);

  transition: linear 200ms;
}
.desc_title {
  color: $light_gray__dark;
  font-weight: 300;
  font-size: 14px;
}

.title-event-modal {
  font-weight: 700;
  font-size: 34px;
  padding-bottom: 15px;
  margin: 0;
}
.spicker {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}
.subtitle-event-modal {
  font-size: 16px;
  color: $light_gray__dark;
  font-weight: 300;
  margin: 0;
  padding-bottom: 5px;
}
.speacker-block {
  padding-bottom: 15px;
}
.event-block {
  padding-bottom: 15px;
}
.about-event_text {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}

.no-events {
  min-height: 80vh;
}
</style>
